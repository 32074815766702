<template>
  <section
    style="
      margin: 0 15px;
      border: 1px #e5e5e5 solid;
      max-height: calc(100vh - 70px);
    "
    class="pool scollDom"
    id="pool"
  >
    <el-row
      :gutter="24"
      style="
        padding: 0 15px 15px;
        background-color: #fff;
        padding-top: 20px;
        margin: 0;
      "
      class="searchBox"
    >
      <el-col :sm="19" :lg="21" class="searchRow" style="padding-left: 0">
        <el-input
          class="input-with-select search"
          type="text"
          v-model.trim="filter.searchKey"
          maxlength="50"
          placeholder=" 请输入客户名称 / 项目名称 /候选人姓名"
          style="width: 100%"
        >
          <el-button type="primary" slot="append" @click="search()"
            >搜 索</el-button
          >
        </el-input>
      </el-col>
      <el-col
        :sm="5"
        :lg="3"
        style="line-height: 40px; text-align: justify; padding-right: 0"
      >
        <span @click="isMoreItemShow = !isMoreItemShow" class="hoverColorChange"
          >{{ isMoreItemShow ? "收起" : "展开" }}更多条件<i
            :class="{
              'el-icon-arrow-up': isMoreItemShow,
              'el-icon-arrow-down': !isMoreItemShow,
            }"
            style="padding-left: 5px"
          ></i
        ></span>
      </el-col>
      <el-col class="searchPanel" v-if="isMoreItemShow">
        <el-form
          @submit.native.prevent
          label-width="110px"
          style="background-color: #fff; padding-right: 20px; padding-top: 20px"
          :model="filter"
          ref="filter"
        >
          <el-col style="padding-left: 0">
            <el-col :lg="8" :md="12" style="padding: 0">
              <el-form-item label="候选人姓名：" prop="candidateName">
                <el-input
                  v-model="filter.candidateName"
                  placeholder="请输入"
                  class="searchInput"
                  style="width: 240px"
                >
                  <template slot="suffix">
                    <el-icon
                      class="el-icon-search"
                      @click.native="search()"
                    ></el-icon> </template
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="8" :md="12" style="padding: 0">
              <el-form-item label="项目名称：" prop="position">
                <el-input
                  v-model="filter.position"
                  placeholder="请输入"
                  class="searchInput"
                  style="width: 240px"
                >
                  <template slot="suffix">
                    <el-icon
                      class="el-icon-search"
                      @click.native="search()"
                    ></el-icon> </template
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="8" :md="12" style="padding: 0">
              <el-form-item label="客户名称：" prop="companySource">
                <el-input
                  v-model="filter.companySource"
                  placeholder="请输入"
                  class="searchInput"
                  style="width: 240px"
                >
                  <template slot="suffix">
                    <el-icon
                      class="el-icon-search"
                      @click.native="search()"
                    ></el-icon>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="8" :md="12" style="padding: 0">
              <el-form-item label="业绩归属行业：" prop="industryIds">
                <el-cascader
                  style="width: 240px; height: 34px"
                  clearable
                  size="mini"
                  filterable
                  :show-all-levels="false"
                  :options="ColumnInList"
                  collapse-tags
                  v-model="filter.industryIds"
                  :props="props"
                  @change="changeIndustryIds"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :lg="8" :md="12" style="padding: 0">
              <el-form-item label="Offer时间：" prop="offerStartDate">
                <el-date-picker
                  v-model="filter.offerStartDate"
                  type="daterange"
                  range-separator="至"
                  @change="getofferStartDate"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  style="width: 240px"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :lg="8" :md="12" style="padding: 0">
              <el-form-item label="预到岗时间：" prop="expectEntryTime">
                <el-date-picker
                  v-model="filter.expectEntryTime"
                  type="daterange"
                  range-separator="至"
                  @change="getexpectEntryTime"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  style="width: 240px"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :lg="8" :md="12" style="padding: 0">
              <el-form-item label="实际到岗时间：" prop="entryTime">
                <el-date-picker
                  v-model="filter.entryTime"
                  type="daterange"
                  range-separator="至"
                  @change="getEntryTime"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  style="width: 240px"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :lg="8" :md="12" style="padding: 0">
              <el-form-item label="财务状态：" prop="status">
                <el-select
                  v-model="filter.status"
                  @change="search()"
                  clearable
                  multiple
                  collapse-tags
                  style="width: 240px"
                >
                  <el-option label="未申请" value="未申请"></el-option>
                  <el-option label="待开票" value="待开票"></el-option>
                  <el-option
                    label="已开票未到账"
                    value="已开票未到账"
                  ></el-option>
                  <el-option
                    label="已开票部分到账"
                    value="已开票部分到账"
                  ></el-option>
                  <el-option
                    label="已开票已到账"
                    value="已开票已到账"
                  ></el-option>
                  <el-option
                    label="未开票部分到账"
                    value="未开票部分到账"
                  ></el-option>
                  <el-option
                    label="未开票已到账"
                    value="未开票已到账"
                  ></el-option>
                  <!-- <el-option
                    label="作废票部分到账"
                    value="作废票部分到账"
                  ></el-option>
                  <el-option
                    label="作废票全部到账"
                    value="作废票全部到账"
                  ></el-option> -->
                  <el-option label="已红冲" value="已红冲"></el-option>
                  <el-option label="待红冲" value="待红冲"></el-option>
                  <!-- <el-option
                    label="红冲部分到账"
                    value="红冲部分到账"
                  ></el-option>
                  <el-option
                    label="红冲全部到账"
                    value="红冲全部到账"
                  ></el-option> -->
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="16" style="padding: 0">
              <el-form-item label="到账时间：" prop="actualAccountDate">
                <el-date-picker
                  v-model="filter.actualAccountDate"
                  type="daterange"
                  range-separator="至"
                  @change="getTime"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  class="dinputr"
                  style="width: 240px"
                ></el-date-picker>
                <el-select
                  v-model="filter.accountDateSearchType"
                  placeholder="请选择"
                  filterable
                  @change="search"
                  class="dinput"
                >
                  <el-option label="按业绩归属时间" value="ATTRIBUTION">
                  </el-option>
                  <el-option label="按实际时间" value="ACTUAL"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="16" style="padding: 0">
              <el-form-item label="开票时间：" prop="actualMakeInvoiceDate">
                <el-date-picker
                  v-model="filter.actualMakeInvoiceDate"
                  type="daterange"
                  @change="getCreateTime"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  style="width: 240px"
                ></el-date-picker>
                <el-select
                  v-model="filter.invoiceDateSearchType"
                  placeholder="请选择"
                  filterable
                  @change="search"
                  class="dinput"
                >
                  <el-option label="按业绩归属时间" value="ATTRIBUTION">
                  </el-option>
                  <el-option label="按实际时间" value="ACTUAL"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="16" style="padding: 0">
              <el-form-item
                label="喜报创建者/参与顾问："
                prop="userIds"
                label-width="170px"
              >
                <el-cascader
                  style="width: 240px"
                  clearable
                  size="mini"
                  filterable
                  :show-all-levels="false"
                  :options="options2"
                  collapse-tags
                  v-model="filter.userIds"
                  :props="props"
                  @change="changeCreateUserIds"
                  @visible-change="handleVisibleChange(2, $event)"
                  :key="keyOfCreateUserIds"
                  :filter-method="filterFun"
                ></el-cascader>
                <el-checkbox
                  style="width: 100px; margin-left: 15px"
                  v-model="filter.showDisabledUser"
                  @change="changeShowDisabledUser"
                  >停用账户</el-checkbox
                >
              </el-form-item>
            </el-col>
          </el-col>
        </el-form>
      </el-col>
    </el-row>
    <div class="box-title" style="padding-bottom: 0">
      <el-row :gutter="24">
        <el-col :span="24">
          <el-button
            size="mini"
            style="margin-left: 10px"
            @click="exportDialog = true"
            >导出报表</el-button
          >
          <el-button
            size="mini"
            style="margin-left: 10px"
            @click="exportSxs('实习生')"
            :loading="exportLoading"
            >导出实习生业绩</el-button
          >
          <el-button
            size="mini"
            style="margin-left: 10px"
            @click="exportSxs('Researcher')"
            :loading="exportLoading1"
            >导出R业绩</el-button
          >
          <el-pagination
            :hide-on-single-page="pagination.total > 10 ? false : true"
            v-if="pagination.total > 0 && !listLoading"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :page-size="pagination.pageSize"
            layout="total,prev, pager, next,sizes"
            :total="pagination.total"
          ></el-pagination>
        </el-col>
        <el-col :span="24">
          <el-table
            :header-cell-style="{
              'background-color': '#f5f7fa',
              'border-top': '1px #e5e5e5 solid',
            }"
            v-horizontal-scroll
            border
            :data="ListData"
            v-sticky="{ top: 0, parent: '.scollDom' }"
            class="tabBorder custor notableBorder"
            v-loading="listLoading"
            :span-method="objectSpanMethod"
            style="margin: 10px 0 10px; border-top: 0"
          >
            <el-table-column
              fixed
              :show-overflow-tooltip="true"
              label="操作"
              align="left"
              width="180"
            >
              <template slot-scope="scope">
                <span
                  v-has="'record:Pool:revise'"
                  v-if="isChange"
                  class="tabHref"
                  @click="handleEdit(scope.row.offerId)"
                  size="mini"
                  >修改业绩
                </span>
                <span
                  v-else
                  class="tabHref"
                  @click="handleEdit(scope.row.offerId)"
                  size="mini"
                  >查看业绩</span
                >

                <el-divider direction="vertical"></el-divider>
                <span class="tabHref" @click="previewJoin(scope.row.offerId)"
                  >查看顾问</span
                >
              </template>
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="候选人"
              fixed
              align="left"
              width="120"
            >
              <template slot-scope="scope">
                <span
                  class="tabHref"
                  @click="drawToCandicate(scope.row.resumeId)"
                  >{{ scope.row.candidateName }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="客户公司名称"
              align="left"
              width="200"
            >
              <template slot-scope="scope">
                <span
                  class="tabHref"
                  @click="drawToCustomer(scope.row.companyId)"
                  >{{ scope.row.comName }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="业绩归属行业"
              align="left"
              prop="industryName"
              width="200"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="项目名称"
              align="left"
              prop="position"
              width="200"
            >
              <template slot-scope="scope">
                <router-link
                  target="_blank"
                  class="tabHref"
                  :to="{
                    path: '/project/details',
                    query: { id: scope.row.projectId },
                  }"
                  >{{ scope.row.position }}</router-link
                >
              </template>
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="服务费用/offer业绩"
              align="left"
              prop="achievement"
              width="170"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="累计开票金额"
              align="left"
              prop="cumulativeInvoicedAmount"
              width="170"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="累计不计税金额"
              align="left"
              prop="noTaxMoneyAmount"
              width="170"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="累计到账金额"
              align="left"
              prop="accumulatedAmountToAccount"
              width="170"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="已开票未到账金额"
              align="left"
              prop="invoicedButNotReceivedAmount"
              width="170"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="剩余未开票金额"
              align="left"
              prop="remainingUnInvoicedAmount"
              width="170"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="剩余未申请金额"
              align="left"
              prop="remainingUnApplyAmount"
              width="170"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="喜报创建者"
              align="left"
              prop="createdUserName"
              width="120"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="实际到岗时间"
              align="left"
              prop="entryTime"
              width="120"
            >
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="喜报创建时间"
              align="left"
              prop="offerCreatedTime"
              width="120"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="单次开票金额"
              align="left"
              prop="payAmount"
              width="170"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="不计税金额"
              align="left"
              prop="noTaxMoney"
              width="170"
            ></el-table-column>

            <!-- <el-table-column
              :show-overflow-tooltip="true"
              label="累计红冲金额"
              align="left"
              prop="performReverseAmount"
              width="170"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="累计红冲不计税金额"
              align="left"
              prop="reverseNoTaxAmount"
              width="170"
            ></el-table-column> -->

            <el-table-column
              :show-overflow-tooltip="true"
              label="已到账金额"
              align="left"
              prop="accountArriveMoney"
              width="170"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="财务状态"
              align="left"
              prop="financeStatus"
              width="200"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="财务任务更新时间"
              align="left"
              prop="lastModifiedTime"
              width="170"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="开票时间"
              align="left"
              prop="actualMakeInvoiceDate"
              width="120"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="开票业绩归属时间"
              align="left"
              prop="invoiceAttributionDate"
              width="130"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="发票号"
              align="left"
              prop="invoiceNumber"
              width="150"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="处理财务"
              align="left"
              prop="dealName"
              width="200"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="财务任务备注"
              align="left"
              prop="remark"
              width="110"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="开票备注"
              align="left"
              prop="invoiceRemark"
              width="200"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="到账金额"
              align="left"
              prop="accountMoney"
              width="170"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="到账时间"
              align="left"
              prop="accountDate"
              width="120"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="到账业绩归属时间"
              align="left"
              prop="accountAttributionDate"
              width="130"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="到账备注"
              align="left"
              prop="accountRemark"
              width="200"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              label="offer业绩修改次数"
              align="left"
              prop="changeCount"
              width="170"
            ></el-table-column>
            <!-- <el-table-column
              :show-overflow-tooltip="true"
              label="累计退款金额"
              align="left"
              prop="refundAmount"
              width="170"
            ></el-table-column> -->
          </el-table>
          <el-pagination
            :hide-on-single-page="pagination.total > 10 ? false : true"
            v-if="pagination.total > 0 && !listLoading"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :page-size="pagination.pageSize"
            layout="total,prev, pager, next,sizes"
            :total="pagination.total"
            style="margin-bottom: 20px"
          ></el-pagination>
        </el-col>
      </el-row>
    </div>
    <!--    站内导出和邮件导出-->
    <el-dialog
      title="请勾选导出项"
      :visible.sync="exportDialog"
      width="720px"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :append-to-body="true"
      @close="closeDialog"
      custom-class="editPercent"
    >
      <template>
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <el-checkbox-group
          v-model="checkedCities"
          @change="handleCheckedCitiesChange"
        >
          <el-checkbox v-for="city in cities" :label="city" :key="city">{{
            city
          }}</el-checkbox>
        </el-checkbox-group>

        <div slot="footer" class="dialog-footer" style="text-align: center">
          <el-button
            size="mini"
            @click="exportPerformancePoolList()"
            :loading="exportBtnLoading"
            :disabled="!checkedCities.length"
            >导出</el-button
          >
        </div>
      </template>
    </el-dialog>
    <!--     查看参与顾问-->
    <el-dialog
      :visible.sync="previewJoinDialog"
      width="600px"
      title="查看参与顾问"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :append-to-body="true"
      custom-class="checkCurson"
    >
      <template>
        <el-form label-width="110px" label-position="right">
          <el-collapse v-model="activeName" accordion>
            <template v-for="(i, inx) in preivewJoinInfo.allocations">
              <el-collapse-item
                :key="inx"
                :name="inx + 1"
                class="checkCursonItem"
              >
                <template slot="title">
                  {{ i.roleName
                  }}<span style="">
                    : {{ i.userName }} ( {{ i.percent }}%)</span
                  >
                </template>
                <div class="con">
                  <div>
                    集团：<span
                      style=""
                      v-for="(y, index) in i.allocationItems.groups"
                      :key="index"
                    >
                      <span v-if="index > 0">、</span>{{ y.departmentName }}-{{
                        y.percent
                      }}%</span
                    >
                  </div>
                  <div>
                    地区：<span
                      style=""
                      v-for="(y, index) in i.allocationItems.areas"
                      :key="index"
                    >
                      <span v-if="index > 0">、</span>{{ y.departmentName }}-{{
                        y.percent
                      }}%
                    </span>
                  </div>
                  <div>
                    团队：<span
                      style=""
                      v-for="(y, index) in i.allocationItems.teams"
                      :key="index"
                    >
                      <span v-if="index > 0">、</span>{{ y.departmentName }}-{{
                        y.percent
                      }}%
                    </span>
                  </div>
                  <div>
                    子团队：<span
                      style=""
                      v-for="(y, index) in i.allocationItems.subTeams"
                      :key="index"
                    >
                      <span v-if="index > 0">、</span>{{ y.departmentName }}-{{
                        y.percent
                      }}%
                    </span>
                  </div>
                </div>
              </el-collapse-item>
            </template>
            <div v-if="preivewJoinInfo.performanceOthers">
              <p
                class="checkCursonItem listRow"
                v-for="(x, inx) in preivewJoinInfo.performanceOthers"
                :key="inx"
              >
                {{ x.type
                }}<span style=""> : {{ x.userName }} ( {{ x.percent }}%)</span>
              </p>
            </div>
          </el-collapse>
        </el-form>
      </template>
    </el-dialog>
    <el-drawer
      :visible.sync="dialogVisible"
      append-to-body
      :modal="false"
      :close-on-click-modal="false"
      :before-close="handleClose"
      custom-class="drawerClass "
      direction="ttb"
    >
      <div slot="title" class="drawerTitle">{{ titName }}</div>
      <div class="drawerCon">
        <div v-if="titName == '修改Offer业绩' || titName == '查看Offer业绩'">
          <el-form
            @submit.native.prevent
            :model="happyNewsForm"
            ref="happyNewsForm"
            class="offerCss"
            :rules="happyNewsFormRule"
            label-width="140px"
          >
            <div class="conbox">
              <h3 @click="collapse2 = !collapse2">
                业绩详情
                <span class="fr cantrol">
                  <i
                    :class="
                      collapse2 ? 'el-icon-arrow-down' : 'el-icon-arrow-right'
                    "
                  ></i>
                </span>
              </h3>
              <el-row v-show="collapse2">
                <div style="margin-top: 10px">
                  <el-col :span="8">
                    <el-form-item prop="candidateName" label="候选人姓名：">
                      {{ happyNewsForm.candidateName }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="companyName" label="公司名称：">
                      {{ happyNewsForm.companyName }}
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="position" label="职位名称：">
                      <el-input
                        v-model="happyNewsForm.position"
                        style="width: 80%"
                        @blur="handlePositionBlur"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="offerTime" label="offer时间：">
                      <el-date-picker
                        v-model="happyNewsForm.offerTime"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        style="width: 80%"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="offerMoney" label="offer金额：">
                      <el-input
                        v-model="happyNewsForm.offerMoney"
                        style="width: 80%"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="expectEntryTime" label="预到岗时间：">
                      <el-date-picker
                        style="width: 80%"
                        v-model="happyNewsForm.expectEntryTime"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="entryTime" label="实际到岗时间：">
                      <el-date-picker
                        style="width: 80%"
                        v-model="happyNewsForm.entryTime"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        :disabled="happyNewsForm.entryTime ? false : true"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="feeStandard" label="收费标准：">
                      <el-input
                        v-model="happyNewsForm.feeStandard"
                        maxlength="100"
                        style="width: 80%"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item prop="money" label="服务费用：">
                      <el-input
                        type="number"
                        @mousewheel.native.prevent
                        v-model="happyNewsForm.money"
                        style="width: 80%"
                        placeholder="请输入服务费用金额"
                        maxlength="12"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item
                      label="业绩归属行业："
                      prop="performanceIndustryIds"
                    >
                      <el-cascader
                        style="width: 80%"
                        clearable
                        size="mini"
                        filterable
                        :show-all-levels="false"
                        :options="ColumnInList"
                        collapse-tags
                        v-model="happyNewsForm.performanceIndustryIds"
                        :props="props1"
                      ></el-cascader>
                    </el-form-item>
                  </el-col>
                </div>
              </el-row>
            </div>

            <div class="conbox">
              <h3 style="margin-bottom: 0" @click="collapse3 = !collapse3">
                业绩分配<span class="sub"
                  >（当前分配比例总和:<i>{{ totalPercent }}%</i>）</span
                >
                <span class="fr cantrol">
                  <i class="addP tabHref" v-if="isChange" @click.stop="add"
                    >+ 添加业绩分配者</i
                  >
                  <i
                    :class="
                      collapse3 ? 'el-icon-arrow-down' : 'el-icon-arrow-right'
                    "
                  ></i>
                </span>
              </h3>
              <div class="searchList" v-show="collapse3">
                <el-collapse accordion>
                  <el-collapse-item
                    class="titleOfPerformance"
                    v-for="(item, index) in happyNewsForm.allocationItems"
                    :key="index"
                  >
                    <template slot="title">
                      <div class="liStyle">
                        <span>
                          <el-select
                            placeholder="请选择"
                            v-model="item.sysAllocationPlanId"
                            @change="changeItem($event, index)"
                            size="mini"
                            style="width: 160px"
                            :disabled="!isChange"
                          >
                            <el-option
                              v-for="(i, indexKPI) in JSON.parse(
                                defaultPerformanceAllocations
                              )"
                              :key="'sel' + indexKPI"
                              :label="i.roleName"
                              :value="i.id"
                              :disabled="i.rdisabled"
                            ></el-option>
                          </el-select>
                        </span>
                        <span>
                          <el-select
                            v-model="item.userId"
                            placeholder="请选择"
                            collapse-tags
                            filterable
                            @change="checkUser(item.userId, index)"
                            style="margin-left: 10px; width: 160px"
                            size="mini"
                            clearable
                            :disabled="!isChange"
                          >
                            <el-option
                              v-for="(i, index) in userList"
                              :key="'selUser' + index"
                              :label="i.name"
                              :value="i.id"
                            ></el-option>
                          </el-select>
                        </span>
                        <el-form-item
                          label="比例："
                          style="display: inline-block"
                          label-width="80px"
                          class="is-required"
                        >
                          <div @click.stop style="display: inline-block">
                            <el-input-number
                              v-model="item.percent"
                              :max="100"
                              :min="0"
                              label="比例"
                              :disabled="!isChange"
                              style="width: 150px"
                            ></el-input-number>
                          </div>
                        </el-form-item>
                        <span style="float: right" v-if="isChange">
                          <i
                            class="el-icon-delete"
                            v-if="happyNewsForm.allocationItems.length > 1"
                            @click.stop="delPercentList(index)"
                          >
                            删除</i
                          >
                        </span>
                      </div>
                    </template>
                    <div class="contentOfPerformance">
                      <el-form>
                        <el-row>
                          <el-col :span="11" class="canEdit">
                            <el-form-item label="集团：">
                              <span
                                v-for="(_item, idx) in item.allocationItems
                                  ? item.allocationItems.groups
                                  : []"
                                :key="idx"
                                >{{ _item.departmentName }} -
                                {{ _item.percent }}%<span
                                  v-if="
                                    item.allocationItems.groups &&
                                    idx !=
                                      item.allocationItems.groups.length - 1
                                  "
                                  >、</span
                                ></span
                              >
                              <i
                                class="iconfont icon-kh_gsxq_bj fr"
                                v-if="isChange"
                                @click="editOrganization('groups', index)"
                              ></i>
                            </el-form-item>
                          </el-col>
                          <el-col :offset="1" :span="11" class="canEdit">
                            <el-form-item label="地区：">
                              <span
                                v-for="(_item, idx) in item.allocationItems
                                  ? item.allocationItems.areas
                                  : []"
                                :key="idx"
                                >{{ _item.departmentName }} -
                                {{ _item.percent }}%<span
                                  v-if="
                                    item.allocationItems.areas &&
                                    idx != item.allocationItems.areas.length - 1
                                  "
                                  >、</span
                                ></span
                              >
                              <i
                                class="iconfont icon-kh_gsxq_bj fr"
                                v-if="isChange"
                                @click="editOrganization('areas', index)"
                              ></i>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="11" class="canEdit">
                            <el-form-item label="团队：">
                              <span
                                v-for="(_item, idx) in item.allocationItems
                                  ? item.allocationItems.teams
                                  : []"
                                :key="idx"
                                >{{ _item.departmentName }} -
                                {{ _item.percent }}%<span
                                  v-if="
                                    item.allocationItems.teams &&
                                    idx != item.allocationItems.teams.length - 1
                                  "
                                  >、</span
                                ></span
                              >
                              <i
                                class="iconfont icon-kh_gsxq_bj fr"
                                v-if="isChange"
                                @click="editOrganization('teams', index)"
                              ></i>
                            </el-form-item>
                          </el-col>
                          <el-col :offset="1" :span="11" class="canEdit">
                            <el-form-item label="子团队：">
                              <span
                                v-for="(_item, idx) in item.allocationItems
                                  ? item.allocationItems.subTeams
                                  : []"
                                :key="idx"
                                >{{ _item.departmentName }} -
                                {{ _item.percent }}%<span
                                  v-if="
                                    item.allocationItems.subTeams &&
                                    idx !=
                                      item.allocationItems.subTeams.length - 1
                                  "
                                  >、</span
                                ></span
                              >
                              <i
                                class="iconfont icon-kh_gsxq_bj fr"
                                v-if="isChange"
                                @click="editOrganization('subTeams', index)"
                              ></i>
                            </el-form-item>
                          </el-col>
                        </el-row>
                      </el-form>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
            <div class="conbox">
              <h3 style="margin-bottom: 0" @click="collapse4 = !collapse4">
                业绩备注<span class="sub"
                  >（实习生/Researcher的业绩详情请备注在这里）</span
                >
                <span class="fr cantrol">
                  <i class="addP tabHref" v-if="isChange" @click.stop="addR"
                    >+ 添加实习生/Researcher备注</i
                  >
                  <i
                    :class="
                      collapse4 ? 'el-icon-arrow-down' : 'el-icon-arrow-right'
                    "
                  ></i>
                </span>
              </h3>
              <div class="searchList" v-show="collapse4">
                <div style="padding: 10px">
                  <div
                    v-for="(item1, index1) in happyNewsForm.performanceOthers"
                    :key="index1"
                    class="titleOfPerformance"
                  >
                    <div class="liStyle">
                      <span>
                        实习生/Researcher：
                        <el-select
                          placeholder="请选择类型"
                          v-model="item1.type"
                          size="mini"
                          clearable
                          :disabled="!isChange"
                        >
                          <el-option
                            v-for="(i, indexKPI) in srType"
                            :key="'sel' + indexKPI"
                            :label="i.name"
                            :value="i.id"
                          ></el-option>
                        </el-select>
                      </span>
                      <span>
                        <el-select
                          v-model="
                            happyNewsForm.performanceOthers[index1].userId
                          "
                          placeholder="请选择"
                          collapse-tags
                          filterable
                          style="width: 200px; margin-left: 10px"
                          size="mini"
                          clearable
                          :disabled="!isChange"
                        >
                          <el-option
                            v-for="(i, index) in userList"
                            :key="'selUser' + index"
                            :label="i.name"
                            :value="i.id"
                          ></el-option>
                        </el-select>
                      </span>
                      <el-form-item
                        label="比例："
                        style="display: inline-block"
                        label-width="80px"
                        :class="item1.type == 'Researcher' ? 'is-required' : ''"
                        :disabled="!isChange"
                      >
                        <div @click.stop style="display: inline-block">
                          <el-input-number
                            v-model="item1.percent"
                            :max="100"
                            :min="0"
                            label="比例"
                            :disabled="!isChange"
                          ></el-input-number>
                        </div>
                      </el-form-item>
                      <span style="margin-left: 10px">
                        团队：
                        <el-select
                          placeholder="请选择团队"
                          v-model="item1.teamId"
                          clearable
                          size="mini"
                        >
                          <el-option
                            v-for="(i, index) in teamsList"
                            :key="'team' + index"
                            :label="i.name"
                            :value="i.id"
                          ></el-option>
                        </el-select>
                      </span>
                      <el-button
                        v-if="index1 > 0"
                        size="mini"
                        type="text"
                        icon="el-icon-delete"
                        @click.stop="delResearcherList(index1)"
                        style="margin-left: 20px"
                        :disabled="!isChange"
                      ></el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="reChangeList conbox">
              <h3 @click="collapse1 = !collapse1" style="margin-bottom: 0">
                变更记录
                <span class="fr cantrol">
                  <i
                    :class="
                      collapse1 ? 'el-icon-arrow-down' : 'el-icon-arrow-right'
                    "
                  ></i>
                </span>
              </h3>
              <ul v-show="collapse1" class="changeRecordList">
                <li
                  v-for="(i, inde) in happyNewsForm.changeRecords"
                  :key="'i' + inde"
                >
                  <el-collapse v-model="changeReasonTab" accordion>
                    <el-collapse-item
                      :title="
                        i.createdTime +
                        ' ' +
                        i.createdUserName +
                        ' 变更，' +
                        ' 变更原因：' +
                        i.changeReason
                      "
                      :name="inde"
                      class="changeRecord"
                    >
                      <div
                        v-html="i.changeDetail"
                        style="margin-bottom: 10px"
                      ></div>
                    </el-collapse-item>
                  </el-collapse>
                </li>
              </ul>
            </div>
          </el-form>
        </div>
        <div
          v-if="titName == '修改Offer业绩'"
          class="drawerCon_footer fixedFoot"
        >
          <el-button size="mini" @click="dialogVisible = false">取消</el-button>
          <el-button
            type="primary"
            size="mini"
            :loading="saveloading"
            @click="addChange('happyNewsForm')"
            style="margin-right: 15px"
            >保存</el-button
          >
        </div>
      </div>
    </el-drawer>
    <el-dialog
      width="600px"
      title="完善信息"
      :visible.sync="innerVisible"
      center
      :close-on-click-modal="false"
      append-to-body
    >
      <div class="clearfix">
        <p class="fl"><i style="color: #ff3f3f">*</i> 变更原因：</p>
        <el-input
          v-model="reason"
          size="mini"
          type="textarea"
          :rows="2"
          maxlength="100"
          placeholder="请填写原因"
          style="width: 400px"
        ></el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button plain @click="innerVisible = false" size="mini"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="saveHappyNews"
          :loading="saveChangeLoading"
          size="mini"
          >保存</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisibleOfEdit"
      width="720px"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :append-to-body="true"
      custom-class="editPercent"
    >
      <el-row :gutter="20" style="padding-bottom: 10px">
        <el-col
          :span="24"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 8px;
          "
        >
          <div style="color: #e58a00">
            累计比例：{{ total }}%（非100%不可保存）
          </div>
          <el-button class="fr" size="mini" @click="addPercent()"
            >添加</el-button
          >
        </el-col>
      </el-row>
      <el-form ref="percentForm" :model="percentForm" :rules="ruleForm">
        <el-row
          style="
            padding: 0 30px 0 10px;
            position: relative;
            height: auto;
            margin-bottom: 15px;
          "
          :gutter="20"
          v-for="(i, idx) in currentAllocationItems"
          :key="idx"
        >
          <el-col :span="12" style="height: 40px">
            <el-form-item label="名称：" :prop="'departmentId' + idx">
              <el-select
                v-model="i.departmentId"
                @change="changeDepartment($event, i)"
                clearable
                filterable
                style="width: 80%"
              >
                <el-option
                  v-for="item in currentList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 40px">
            <el-form-item
              label="比例%："
              label-width="80px"
              :prop="'percent' + idx"
            >
              <el-input
                v-model.trim.number="i.percent"
                style="width: 100%"
                @mousewheel.native.prevent
              ></el-input>
            </el-form-item>
          </el-col>
          <i class="el-icon-delete iconOfClose" @click="delPercent(idx)"></i>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogVisibleOfEdit = false"
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="saveEditPercent()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!--    该业绩有以下开票申请记录-->
    <el-dialog
      title="提示"
      :visible.sync="recordApplicationDialog"
      width="600px"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :append-to-body="true"
      custom-class="editPercent"
    >
      <h4 style="text-align: center">该业绩有以下开票申请记录</h4>
      <el-table
        :header-cell-style="{
          'background-color': '#f5f7fa',
        }"
        :data="recordApplicationData"
        max-height="300px"
        class="tabBorder"
        v-loading="recordApplicationLoading"
        :border="true"
      >
        <el-table-column
          :show-overflow-tooltip="true"
          label="编号"
          align="left"
          width="60"
          prop="id"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          label="财务状态"
          align="left"
          prop="status"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          label="申请开票金额"
          align="left"
          width="100"
          prop="payAmount"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          label="有效开票金额"
          width="100"
          align="left"
          prop="activeInvoiceMoney"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          label="实际开票时间"
          width="110"
          align="left"
          prop="actualMakeInvoiceDate"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          label="到账金额"
          align="left"
          prop="accountMoney"
        ></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="recordApplicationDialog = false"
          >放弃修改</el-button
        >
        <el-button size="mini" type="primary" @click="innerVisible = true"
          >继续修改</el-button
        >
      </div>
    </el-dialog>
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        <i
          v-if="drawerTitle == '客户'"
          class="drawIcon iconfont icon-kh_xq"
        ></i>
        <i
          v-if="drawerTitle == '人才'"
          class="drawIcon iconfont icon-rc_bt"
        ></i>
        {{ drawerTitle }}
      </div>
      <div class="drawerCon">
        <addoreEditCus
          v-if="drawerTitle == '编辑客户' && drawerVisible"
          @loadList="loadList"
          @tuComInfo="detailsPop"
          :customId="customId"
        ></addoreEditCus>
        <customerInfo
          v-if="drawerTitle == '客户' && drawerVisible"
          @toEdit="toEditComInfo"
          :customId="customId"
          :activeToInfo="activedToInfo"
          @loadList="loadList"
        ></customerInfo>
        <resumeDetails
          v-if="drawerTitle == '人才' && drawerVisible"
          :resumeDetailId="resumeId"
          :hover="hoverInfo"
          :repeatResume="repeatResume"
        ></resumeDetails>
      </div>
    </el-drawer>
    <!-- 服务费为0 -->
    <el-dialog
      width="600px"
      title="补充信息"
      :visible.sync="zeroInnerVisible"
      center
      :close-on-click-modal="false"
      append-to-body
    >
      <div>
        <p style="text-align: center; margin-bottom: 15px">
          系统监测到本次服务费调整为0，请完善以下信息
        </p>
        <div class="clearfix">
          <p class="fl zfl"><i style="color: #ff3f3f">*</i> 原因：</p>
          <el-select v-model="zeroreason" placeholder="请选择">
            <el-option
              v-for="item in zeroreasons"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="zeroTit">
          <p>注意提交后：</p>
          <p>1.更新5类业绩数据；</p>
          <p>2.pipeline中该候选人自动进入失败阶段</p>
        </div>
        <el-form
          @submit.native.prevent
          label-position="top"
          v-if="
            CommentWay != null &&
            CommentWay.columns &&
            CommentWay.columns.length > 0
          "
        >
          <el-row :gutter="24">
            <el-col
              :span="24"
              v-for="(item, index) in CommentWay.columns"
              :key="item.id"
            >
              <el-form-item
                :label="index + 1 + '.' + item.title"
                :required="item.required"
                class="selectLabel"
              >
                <div v-if="item.type == '单选型' && item.selectType == '单选'">
                  <el-radio-group v-model="item.answer">
                    <p v-for="(i, _index) in item.options" :key="_index">
                      <el-radio
                        :label="i"
                        @click.native.prevent="clickItem(i, index)"
                        >{{ i }}</el-radio
                      >
                    </p>
                  </el-radio-group>
                </div>
                <div v-if="item.type == '单选型' && item.selectType == '下拉'">
                  <el-select
                    v-model="item.answer"
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="(i, _index) in item.options"
                      :key="_index"
                      :label="i"
                      :value="i"
                    ></el-option>
                  </el-select>
                </div>
                <div v-if="item.type == '多选型'">
                  <el-checkbox-group v-model="item.answer1">
                    <p v-for="(i, _index) in item.options" :key="_index">
                      <el-checkbox :label="i">{{ i }}</el-checkbox>
                    </p>
                  </el-checkbox-group>
                </div>
                <div v-if="item.type == '文本描述型'">
                  <el-input
                    v-model="item.answer"
                    placeholder="请输入内容"
                    type="textarea"
                    :rows="3"
                  ></el-input>
                </div>
                <div v-if="item.type == '日期型'">
                  <el-date-picker
                    prefix-icon="el-icon-date"
                    v-model="item.answer"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy/MM/dd"
                  ></el-date-picker>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button plain @click="zeroInnerVisible = false" size="mini"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="saveHappyNews1"
          :loading="saveChangeLoading1"
          size="mini"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </section>
</template>
<script>
import {
  getCompanySource,
  allocationPool,
  validateMoney,
  nameAndStatusOptions, //获取用户列表
  performanceInfo, //通过id获取详情
  performanceEdit,
  getGroupsByType, // 获取集团
  getAreasByType, // 获取地区
  getTeamsByType, // 获取团队
  getSubTeamsByType, // 获取子团队
  departAllocations, //获取用户的集团,地区,团队分配数据
  performancePoolList, //获取业绩列表
  exportPerformancePoolList, //导出业绩列表
  getCommonColumnList, //获取行业列表
  permissionTree, //获取用户的组织架构
  getAllocationInfo, //查看顾问分配方案
  latestComment, // 获取最新备注
  editToZero, //业绩修改为0
  exportOther, //导出实习生或Researcher业绩
} from "../../api/api";
import customerInfo from "./../../components/customer/customerInfo";
import addoreEditCus from "./../../components/customer/addorEditCustomer";
import resumeDetails from "../../components/resumeDetail";
import { ApiBaseUrl } from "../../api/http";
import axios from "axios";
export default {
  components: { customerInfo, addoreEditCus, resumeDetails },
  data() {
    const cityOptions = [
      "候选人",
      "客户公司名称",
      "业绩归属行业",
      "项目名称",
      "服务费用/offer业绩",
      "累计开票金额",
      "累计不计税金额",
      "累计到账金额",
      "已开票未到账金额",
      "剩余未开票金额",
      "剩余未申请金额",
      "喜报创建者",
      "预计到岗时间",
      "实际到岗时间",
      "喜报创建时间",
      "Offer时间",
      "简历拥有者",
      "简历拥有者所属团队",
      "简历拥有者所占比例",
      "项目经理",
      "项目经理所属团队",
      "项目经理所占比例",
      "BD-线索顾问",
      "BD-线索顾问所属团队",
      "BD-线索顾问所占比例",
      "BD-主导顾问",
      "BD-主导顾问所属团队",
      "BD-主导顾问所占比例",
      "交付-搜寻顾问",
      "交付-搜寻顾问所属团队",
      "交付-搜寻顾问所占比例",
      "交付-谈判顾问",
      "交付-谈判顾问所属团队",
      "交付-谈判顾问所占比例",
      "交付-跟进顾问",
      "交付-跟进顾问所属团队",
      "交付-跟进顾问所占比例",
      "交付-维护顾问",
      "交付-维护顾问所属团队",
      "交付-维护顾问所占比例",
      "签约公司",
      "单次开票金额",
      "不计税金额",
      // "累计红冲金额",
      // "累计红冲不计税金额",
      "已到账金额",
      "财务任务更新时间",
      "财务状态",
      "发票号",
      "开票时间",
      "开票业绩归属时间",
      "处理财务",
      "财务任务备注",
      "开票备注",
      "到账金额",
      "到账时间",
      "到账业绩归属时间",
      "到账备注",
      "offer业绩修改次数",
      // "累计退款金额",
    ];
    let checkCent = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入金额"));
      }
      setTimeout(() => {
        let reg = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
        if (!reg.test(value)) {
          callback(new Error("请输入数字（小数点后两位）"));
        } else {
          callback();
        }
      }, 500);
    };
    let checkCent1 = (rule, value, callback) => {
      if (value < 0 || value == null) {
        return callback(new Error("请输入金额"));
      }
      setTimeout(() => {
        let reg = /^(([0-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
        if (!reg.test(value)) {
          callback(new Error("请输入数字（小数点后两位）"));
        } else {
          callback();
        }
      }, 500);
    };
    return {
      source: null,
      drawerVisible: false,
      drawerTitle: "",
      customId: null, //客户id
      resumeId: null, //候选人id
      repeatResume: { type: "列表", valId: "" },
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      activedToInfo: "1",
      isMoreItemShow: false,
      previewJoinList: ["简历拥有者", ""],
      isChange: true,
      permission: JSON.parse(this.getStore("permiss")),
      keyOfPartnerIds: 0,
      keyOfCreateUserIds: 0,
      previewJoinDialog: false,
      recordApplicationDialog: false,
      recordApplicationLoading: false,
      preivewJoinInfo: "",
      activeName: "",
      exportBtnLoading: false,
      download: false,
      exportDialog: false,
      baseUrl: ApiBaseUrl,
      spanArr: [],
      checkAll: false,
      fileUrl: "",
      allocationId: "",
      checkedCities: [],
      recordApplicationData: [],
      areas: [],
      teams: [],
      groups: [],
      subTeams: [],
      cities: cityOptions,
      isIndeterminate: true,
      spanArr1: [],
      spanArr2: [],
      annexListdata: [],
      content: "业绩池报表",
      receiverList: [],
      ColumnInList: [], //行业列表
      options2: [], //用户组织架构
      props: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
        checkStrictly: false,
      },
      props1: {
        value: "id",
        label: "name",
        children: "children",
        multiple: false,
        checkStrictly: false,
      },
      groupsList: [],
      areasList: [],
      teamsList: [],
      subTeamsList: [],
      currentEditType: "",
      currentIndex: 0,
      currentList: [],
      currentAllocationItems: [],
      dialogVisibleOfEdit: false,
      radioGroup: "",
      isMoreShow: false,
      searchUserId: "",
      ListData: [],
      getTimeOption: [],
      userList: [], //用户列表
      companySourceList: [],
      titName: "",
      reason: "",
      collapse1: false,
      collapse2: true,
      collapse3: true,
      collapse4: true,
      listLoading: false,
      saveloading: false,
      saveChangeLoading: false,
      dialogVisible: false,
      innerVisible: false,
      filter: {
        searchKey: "",
        actualAccountDate: "",
        actualMakeInvoiceDate: "",
        candidateName: "",
        industryIds: [],
        companySource: "",
        entryTime: "",
        // partnerIds: [],
        position: "",
        status: [],
        showDisabledUser: false,
        userIds: [],
        accountDateSearchType: "ATTRIBUTION",
        invoiceDateSearchType: "ATTRIBUTION",
      },
      userIds: [],
      industryIds: [],
      changeReasonTab: "",
      changeReasonNew: 0,
      happyNewsForm: {
        changeRecords: [],
        candidateName: null,
        companyName: null,
        entryTime: null,
        expectEntryTime: null,
        feeStandard: null,
        id: 0,
        money: 0,
        offerMoney: 0,
        offerTime: null,
        display: false,
        performanceAllocations: [],
        position: null,
        projectResumeId: 0,
        version: 0,
        performanceOthers: [],
        performanceIndustryIds: null,
      },
      happyNewsFormRule: {
        money: [{ required: true, validator: checkCent1, trigger: "blur" }],
        position: [
          { required: true, message: "职位不能为空", trigger: "blur" },
        ],
        offerTime: [
          { required: true, message: "offer时间不能为空", trigger: "change" },
        ],
        expectEntryTime: [
          { required: true, message: "预到岗时间不能为空", trigger: "change" },
        ],
        offerMoney: [{ required: true, validator: checkCent, trigger: "blur" }],
        feeStandard: [
          { required: true, message: "收费标准不能为空", trigger: "blur" },
        ],
        performanceIndustryIds: [
          {
            required: true,
            message: "业绩归属行业不能为空",
            trigger: "change",
          },
        ],
      },
      offerT: [],
      CreateT: [],
      EnterT: [],
      offerEnterT: [],
      exEnterT: [],
      defaultPerformanceAllocations: [],
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 50,
      },
      srType: [
        {
          name: "实习生",
          id: "实习生",
        },
        {
          name: "Researcher",
          id: "Researcher",
        },
      ],
      activeNames1: ["score"],
      ResearcherList: [],
      zeroInnerVisible: false,
      zeroreasons: [
        {
          value: "候选人拒绝",
          label: "候选人拒绝",
        },
        {
          value: "未过试用期",
          label: "未过试用期",
        },
        {
          value: "试用期离职",
          label: "试用期离职",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
      zeroreason: "",
      saveChangeLoading1: false,
      CommentWay: "", //反馈对应备注方案
      exportLoading: false,
      exportLoading1: false,
      time: 5,
    };
  },
  computed: {
    totalPercent: function () {
      let totalPercent = 0;
      for (let i in this.happyNewsForm.allocationItems) {
        if (this.happyNewsForm.allocationItems[i].percent) {
          totalPercent += this.happyNewsForm.allocationItems[i].percent;
        }
      }
      return totalPercent;
    },
    total: {
      get() {
        let total = 0;
        if (
          this.currentAllocationItems &&
          this.currentAllocationItems.length > 0
        ) {
          this.currentAllocationItems.forEach((item) => {
            if (item.departmentId && item.percent) {
              total += item.percent;
            }
          });
        }
        return total;
      },
      set() {},
    },
    percentForm() {
      let percentForm = {};
      this.currentAllocationItems.forEach((item, index) => {
        percentForm["departmentId" + index] = item.departmentId;
        percentForm["percent" + index] = item.percent;
      });
      return percentForm;
    },
    ruleForm() {
      let ruleForm = {};
      this.currentAllocationItems.forEach((item, index) => {
        ruleForm["departmentId" + index] = [
          { required: true, message: "内容不能为空", trigger: "change" },
        ];
        ruleForm["percent" + index] = [
          { required: true, message: "内容不能为空", trigger: "change" },
          {
            pattern: /^(([1-9][0-9]?)|100)$/,
            message: "范围1-100",
            trigger: "change",
          },
        ];
      });
      return ruleForm;
    },
  },
  methods: {
    drawToCandicate(id) {
      this.drawerVisible = true;
      this.resumeId = id;
      this.drawerTitle = "人才";
    },
    drawToCustomer(id) {
      this.customId = id;
      this.drawerVisible = true;
      this.drawerTitle = "客户";
    },
    toEditComInfo(id) {
      this.drawerTitle = "编辑客户";
      this.customId = id;
    },
    detailsPop(id) {
      this.drawerVisible = true;
      this.drawerTitle = "客户";
      this.customId = id; //客户id
      this.activedToInfo = "1";
    },
    // @ApiModelProperty(value = "结果类型", notes = "0 业绩无变化 1. 无开票信息 2. 修改后金额大于等于有效开票金额 3. 修改后金额小于有效开票金额 ")
    // private Integer type;
    addChange(formName) {
      this.recordApplicationLoading = true;

      if (
        !this.happyNewsForm.position ||
        !this.happyNewsForm.offerTime ||
        !this.happyNewsForm.expectEntryTime ||
        !this.happyNewsForm.offerMoney ||
        !this.happyNewsForm.feeStandard ||
        this.happyNewsForm.money < 0
      ) {
        this.$message.error("业绩详情信息不能为空");
        return;
      }
      this.reason = "";
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.totalPercent != 100) {
            this.$message.error("当前分配比例总和不是100%");
            return;
          }

          for (let i in this.happyNewsForm.allocationItems) {
            if (!this.happyNewsForm.allocationItems[i].id) {
              this.$message.error("业绩分配人角色不能为空");
              return;
            }
            if (!this.happyNewsForm.allocationItems[i].userId) {
              this.$message.error("业绩分配人不能为空");
              return;
            }
            if (this.happyNewsForm.allocationItems[i].percent < 0) {
              this.$message.error("比例不能为负");
              return;
            }
            if (
              !Number.isInteger(this.happyNewsForm.allocationItems[i].percent)
            ) {
              this.$message.error("请输入数字值");
              return;
            }
          }
          for (let y in this.happyNewsForm.performanceOthers) {
            if (this.happyNewsForm.performanceOthers[y].type) {
              if (!this.happyNewsForm.performanceOthers[y].userId) {
                this.$message.error("实习生/Researcher业绩分配人不能为空");
                return;
              }
              if (!this.happyNewsForm.performanceOthers[y].teamId) {
                this.$message.error("实习生/Researcher团队不能为空");
                return;
              }
            }
            if (this.happyNewsForm.performanceOthers[y].userId) {
              if (!this.happyNewsForm.performanceOthers[y].type) {
                this.$message.error("实习生/Researcher业绩分配人角色不能为空");
                return;
              }
            }
            if (this.happyNewsForm.performanceOthers[y].type == "Researcher") {
              if (
                !Number.isInteger(
                  this.happyNewsForm.performanceOthers[y].percent
                ) ||
                this.happyNewsForm.performanceOthers[y].percent == 0
              ) {
                this.$message.error("请输入Researcher比例");
                return;
              }
            }
          }
          validateMoney(this.happyNewsForm).then((res) => {
            if (res.result.type == 3) {
              this.$confirm(
                `该业绩已申请开票 ${res.result.invoiceMoney}元，新修改的服务费不能低于此金额请调整!`,
                "提示",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  customClass: "altCls",
                  type: "warning",
                }
              )
                .then(() => {})
                .catch(() => {});
            } else if (res.result.type == 2) {
              this.recordApplicationDialog = true;
              this.recordApplicationLoading = false;
              this.recordApplicationData = res.result.financeTasks;
            } else {
              if (this.happyNewsForm.money == 0) {
                this.feedBack();
                this.zeroInnerVisible = true;
              } else {
                this.innerVisible = true;
              }
            }
          });
        }
      });
    },
    closeDialog() {
      this.checkedCities = [];
    },
    previewJoin(id) {
      this.previewJoinDialog = true;
      getAllocationInfo(id).then((res) => {
        if (res.success) {
          this.preivewJoinInfo = res.result;
        }
      });
    },
    getAllocationId(id) {
      if (typeof id == "number") {
        allocationPool({ allocationId: id }).then((res) => {
          this.areas = res.result.areas
            .map((i) => i.departmentName + " - " + i.percent + "%")
            .join("、 ");
          this.groups = res.result.groups
            .map((i) => i.departmentName + " - " + i.percent + "%")
            .join("、 ");
          this.subTeams = res.result.subTeams
            .map((i) => i.departmentName + " - " + i.percent + "%")
            .join("、 ");
          this.teams = res.result.teams
            .map((i) => i.departmentName + " - " + i.percent + "%")
            .join("、 ");
        });
      } else {
        this.areas = null;
        this.groups = null;
        this.subTeams = null;
        this.teams = null;
      }
    },

    handleCheckAllChange(val) {
      this.checkedCities = val ? this.cities : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },
    handlePositionBlur() {
      this.happyNewsForm.position = this.happyNewsForm.position.trim();
    },
    // 判断是否有修改权限
    isShowChange() {
      this.isChange = false;
      this.permission.forEach((item) => {
        if (item.authority == "record:Pool:revise") {
          this.isChange = true;
        }
      });
    },
    // 清空搜索条件
    clearSearch() {
      this.filter = {
        actualAccountDate: "",
        actualMakeInvoiceDate: "",
        candidateName: "",
        industryIds: [],
        companySource: "",
        entryTime: "",
        position: "",
        status: [],
        showDisabledUser: false,
        userIds: [],
      };
      this.EnterT[0] = "";
      this.EnterT[1] = "";
      this.exEnterT[0] = "";
      this.exEnterT[1] = "";
      this.offerT[0] = "";
      this.offerT[1] = "";
      this.CreateT[0] = "";
      this.CreateT[1] = "";
      this.offerEnterT[0] = [];
      this.offerEnterT[1] = [];
      this.userIds = [];
      this.industryIds = [];
    },
    radioClick(id, name, index) {
      if (this.isSearchPlanSelected || this.radioGroup != name) {
        this.radioGroup = name;
        this.isSearchPlanSelected = false;
      } else {
        this.radioGroup = "";
        this.isSearchPlanSelected = true;
        this.createSearch = {
          id: 0,
          name: "",
          searchList: {},
          type: "人选",
          version: 0,
        };
      }
    },
    // 获取到账时间
    getTime(val) {
      this.offerT = val;
      if (!this.offerT) {
        this.offerT = [];
      }
      this.search();
    },
    // 实际到岗时间
    getEntryTime(val) {
      this.EnterT = val;
      if (!this.EnterT) {
        this.EnterT = [];
      }
      this.search();
    },
    // offer时间
    getofferStartDate(val) {
      this.offerEnterT = val;
      if (!this.offerEnterT) {
        this.offerEnterT = [];
      }
      this.search();
    },
    // 预到岗时间
    getexpectEntryTime(val) {
      this.exEnterT = val;
      if (!this.exEnterT) {
        this.exEnterT = [];
      }
      this.search();
    },
    // 获取开票时间
    getCreateTime(val) {
      this.CreateT = val;
      if (!this.CreateT) {
        this.CreateT = [];
      }
      this.search();
    },

    loadUserBase() {
      //获取用户列表
      nameAndStatusOptions().then((res) => {
        if (res.success) {
          this.userList = res.result;
        }
      });
    },
    changeItem: function (val, index) {
      let defaultPerformanceAllocations = JSON.parse(
        this.defaultPerformanceAllocations
      );
      for (let x = 0; x < this.ResearcherList.length; x++) {
        if (this.ResearcherList[x].id) {
          if (
            this.ResearcherList[x].sysAllocationPlanId !==
            this.happyNewsForm.allocationItems[x].sysAllocationPlanId
          ) {
            for (let i = 0; i < defaultPerformanceAllocations.length; i++) {
              if (
                defaultPerformanceAllocations[i].sysAllocationPlanId ==
                this.ResearcherList[x].sysAllocationPlanId
              ) {
                defaultPerformanceAllocations[i].rdisabled = false;
              }
              if (defaultPerformanceAllocations[i].sysAllocationPlanId == val) {
                defaultPerformanceAllocations[i].rdisabled = true;
                this.$set(
                  this.happyNewsForm.allocationItems,
                  index,
                  defaultPerformanceAllocations[i]
                );
              }
            }
            this.defaultPerformanceAllocations = JSON.stringify(
              defaultPerformanceAllocations
            );
          }
        } else {
          for (let i = 0; i < defaultPerformanceAllocations.length; i++) {
            if (defaultPerformanceAllocations[i].sysAllocationPlanId == val) {
              defaultPerformanceAllocations[i].rdisabled = true;
              this.$set(
                this.happyNewsForm.allocationItems,
                index,
                defaultPerformanceAllocations[i]
              );
            }
          }
          this.defaultPerformanceAllocations = JSON.stringify(
            defaultPerformanceAllocations
          );
        }
      }
      this.ResearcherList = JSON.parse(
        JSON.stringify(this.happyNewsForm.allocationItems)
      );
    },
    add() {
      this.happyNewsForm.allocationItems.push({
        allocationItems: { groups: [], areas: [], teams: [], subTeams: [] },
        roleName: "",
        userId: "",
        percent: 0,
        version: 0,
      });
      this.ResearcherList.push({
        allocationItems: { groups: [], areas: [], teams: [], subTeams: [] },
        roleName: "",
        userId: "",
        percent: 0,
        version: 0,
      });
    },
    addR() {
      let data = this.happyNewsForm.performanceOthers;
      data.push({
        userId: "",
        id: "",
        percent: 0,
        teamId: null,
      });
      this.happyNewsForm.performanceOthers = data;
      this.$forceUpdate();
    },

    delPercentList(index) {
      this.$confirm("是否删除该项?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          let data = JSON.parse(this.defaultPerformanceAllocations);
          for (var i = 0; i < data.length; i++) {
            if (
              data[i].sysAllocationPlanId ==
              this.happyNewsForm.allocationItems[index].sysAllocationPlanId
            ) {
              data[i].rdisabled = false;
            }
          }
          this.defaultPerformanceAllocations = JSON.stringify(data);
          this.happyNewsForm.allocationItems.splice(index, 1);
          this.ResearcherList.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    delResearcherList(index) {
      this.happyNewsForm.performanceOthers.splice(index, 1);
    },
    saveHappyNews: function () {
      this.$refs.happyNewsForm.validate((valid) => {
        if (valid) {
          if (this.reason == "" || !this.reason) {
            this.$message.error("请填写变更原因");
            return false;
          }
          this.happyNewsForm.changeRecords.push({
            changeReason: this.reason,
          });
          this.saveChangeLoading = true;
          performanceEdit(this.happyNewsForm).then((res) => {
            if (res.success) {
              this.innerVisible = false;
              this.recordApplicationDialog = false;
              this.dialogVisible = false;
              this.$message.success(res.message);
              this.$refs.happyNewsForm.resetFields();
              this.reason = "";
              this.loadList();
            }
            this.saveChangeLoading = false;
          });
        } else {
          return false;
        }
      });
    },
    // 反馈
    feedBack() {
      latestComment({ type: "入职" }).then((res) => {
        if (res.success) {
          this.CommentWay = res.result;
          this.CommentWay.columns.forEach((item) => {
            this.$set(item, "answer1", []);
            this.$set(item, "answer", "");
          });
        }
      });
    },
    //删除左右两端的空格
    trim(str) {
      let a = "";
      a = str.replace(/(^\s*)|(\s*$)/g, "");
      return a;
    },
    saveHappyNews1: function () {
      this.$refs.happyNewsForm.validate((valid) => {
        if (valid) {
          if (this.zeroreason == "" || !this.zeroreason) {
            this.$message.error("请填写原因");
            return false;
          }
          let commentRecord = {
            commentColumns: [],
            commentId: this.CommentWay.id,
            id: 0,
            type: this.CommentWay.type,
            version: 0,
          };
          let columns = this.CommentWay.columns;
          for (let i = 0; i < columns.length; i++) {
            columns[i].answer = this.trim(columns[i].answer);
            if (
              columns[i].required &&
              (columns[i].answer == "" || columns[i].answer == null) &&
              columns[i].answer1.length == 0
            ) {
              this.$message.error("请将必填项填写完整");
              return false;
            }
          }
          columns.map((item) => {
            commentRecord.commentColumns.push({
              columnTitle: item.title,
              commentColumnId: item.id,
              type: item.type,
              value:
                item.type == "多选型" ? item.answer1.join(",") : item.answer,
            });
          });
          let from = {};
          from.performanceInfo = this.happyNewsForm;
          from.entryStatus = this.zeroreason;
          from.commentRecord = commentRecord;
          this.saveChangeLoading1 = true;
          editToZero(from).then((res) => {
            if (res.success) {
              this.zeroInnerVisible = false;
              this.recordApplicationDialog = false;
              this.dialogVisible = false;
              this.$message.success(res.message);
              this.$refs.happyNewsForm.resetFields();
              this.zeroreason = "";
              this.loadList();
            }
            this.saveChangeLoading1 = false;
          });
        } else {
          return false;
        }
      });
    },
    handleEdit(id) {
      this.dialogVisible = true;
      this.titName = this.isChange ? "修改Offer业绩" : "查看Offer业绩";
      performanceInfo({
        id: id,
      }).then((res) => {
        if (res.success) {
          this.happyNewsForm = res.result;
          this.happyNewsForm.id = id;
          this.ResearcherList = JSON.parse(
            JSON.stringify(res.result.allocationItems)
          );
          this.happyNewsForm.performanceAllocations.map((item) => {
            if (!item.allocationItems) {
              return (item.allocationItems = {
                groups: [],
                areas: [],
                teams: [],
                subTeams: [],
              });
            } else {
              Object.keys(item.allocationItems).map((i) => {
                if (!item.allocationItems[i]) {
                  return (item.allocationItems[i] = []);
                }
              });
            }
          });
          this.defaultPerformanceAllocations = JSON.stringify(
            this.happyNewsForm.performanceAllocations
          );
          this.happyNewsForm.performanceOthers = res.result.performanceOthers
            ? res.result.performanceOthers
            : [
                {
                  type: "",
                  id: "",
                  percent: 0,
                  teamId: null,
                },
              ];
          let performanceIdList = [];
          res.result.allocationItems.forEach((item) => {
            performanceIdList.push(
              this.userList.find((_item) => {
                return _item.id == item.userId;
              }) || {}
            );
          });
        }
      });
    },
    handleClose(done) {
      done();
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.pagination.pageSize = val;
      this.pagination.pageNo = 1;
      this.loadList();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.pagination.pageNo = val;
      this.loadList();
    },
    loadList() {
      $("#pool").scrollTop(0);
      this.listLoading = true;
      if (this.drawerTitle == "编辑客户") {
        this.drawerTitle = "客户";
      }
      performancePoolList({
        actualAccountEndDate: this.offerT[1] ? this.offerT[1] : "",
        actualAccountStartDate: this.offerT[0] ? this.offerT[0] : "",
        actualMakeInvoiceEndDate: this.CreateT[1] ? this.CreateT[1] : "",
        actualMakeInvoiceStartDate: this.CreateT[0] ? this.CreateT[0] : "",
        candidateName: this.filter.candidateName,
        industryIds: this.industryIds,
        companySource: this.filter.companySource,
        entryEndTime: this.EnterT[1] ? this.EnterT[1] : "",
        entryStartTime: this.EnterT[0] ? this.EnterT[0] : "",
        expectEntryEndTime: this.exEnterT[1] ? this.exEnterT[1] : "",
        expectEntryStartTime: this.exEnterT[0] ? this.exEnterT[0] : "",
        offerStartDate: this.offerEnterT[0] ? this.offerEnterT[0] : "",
        offerEndDate: this.offerEnterT[1] ? this.offerEnterT[1] : "",
        page: {
          current: this.pagination.pageNo,
          size: this.pagination.pageSize,
        },
        position: this.filter.position,
        status: this.filter.status,
        showDisabledUser: this.filter.showDisabledUser,
        userIds: this.userIds,
        searchKey: this.filter.searchKey,
        accountDateSearchType: this.filter.accountDateSearchType,
        invoiceDateSearchType: this.filter.invoiceDateSearchType,
      }).then((res) => {
        if (res.success) {
          this.ListData = res.result.records;
          this.ListData = this.ListData.map((item) => {
            for (let key in item) {
              if (item[key] == null) {
                item[key] = "-";
              }
            }
            return item;
          });
          let pos = 0;
          let pos1 = 0;
          let pos2 = 0;
          this.spanArr = [];
          this.spanArr1 = [];
          this.spanArr2 = [];
          for (var i = 0; i < this.ListData.length; i++) {
            if (i === 0) {
              this.spanArr.push(1);
              this.spanArr1.push(1);
              this.spanArr2.push(1);
              pos = 0;
            } else {
              // 判断当前元素与上一个元素是否相同,因合并第一个所以[0]
              if (
                this.ListData[i].offerProjectResumeId ===
                this.ListData[i - 1].offerProjectResumeId
              ) {
                if (
                  this.ListData[i].financeTaskId &&
                  this.ListData[i].financeTaskId ===
                    this.ListData[i - 1].financeTaskId
                ) {
                  this.spanArr1[pos1] += 1;
                  this.spanArr1.push(0);
                } else {
                  this.spanArr1.push(1);
                  pos1 = i;
                }
                // 到账金额 到账时间 到账备注 -- 合并
                if (
                  this.ListData[i].accountId &&
                  this.ListData[i].accountId === this.ListData[i - 1].accountId
                ) {
                  this.spanArr2[pos2] += 1;
                  this.spanArr2.push(0);
                } else {
                  this.spanArr2.push(1);
                  pos2 = i;
                }

                this.spanArr[pos] += 1;
                this.spanArr.push(0);
              } else {
                this.spanArr.push(1);
                pos = i;
                this.spanArr1.push(1);
                pos1 = i;
                this.spanArr2.push(1);
                pos2 = i;
              }
            }
          }
          this.pagination.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    getCompanySourceList() {
      // 获取客户来源数据
      getCompanySource().then((res) => {
        if (res.success) {
          res.result.forEach((item, index) => {
            this.$set(this.companySourceList, index, {
              value: item,
              label: item,
            });
          });
        }
      });
    },
    // 编辑分配比例
    editOrganization(type, index) {
      this.dialogVisibleOfEdit = true;
      this.currentEditType = type;
      this.currentIndex = index;
      this.total = 0;
      if (type == "groups") {
        this.currentList = this.groupsList;
        this.currentAllocationItems = JSON.parse(
          JSON.stringify(
            this.happyNewsForm.allocationItems[this.currentIndex]
              .allocationItems.groups
          )
        );
      } else if (type == "areas") {
        this.currentList = this.areasList;
        this.currentAllocationItems = JSON.parse(
          JSON.stringify(
            this.happyNewsForm.allocationItems[this.currentIndex]
              .allocationItems.areas
          )
        );
      } else if (type == "teams") {
        this.currentList = this.teamsList;
        this.currentAllocationItems = JSON.parse(
          JSON.stringify(
            this.happyNewsForm.allocationItems[this.currentIndex]
              .allocationItems.teams
          )
        );
      } else if (type == "subTeams") {
        this.currentList = this.subTeamsList;
        this.currentAllocationItems = JSON.parse(
          JSON.stringify(
            this.happyNewsForm.allocationItems[this.currentIndex]
              .allocationItems.subTeams
          )
        );
      }
    },
    changeDepartment(e, i) {
      this.currentList.forEach((item) => {
        if (item.id == e) {
          i.departmentName = item.name;
        }
      });
    },
    // 添加分配比例项
    addPercent() {
      this.currentAllocationItems.push({
        departmentId: "",
        departmentName: "",
        percent: "",
      });
    },
    // 删除分配比例项
    delPercent(i) {
      this.currentAllocationItems.splice(i, 1);
    },
    // 保存分配比例
    saveEditPercent() {
      this.$refs["percentForm"].validate((valid) => {
        if (valid) {
          if (this.total != 100 && this.total > 0) {
            this.$message.error("当前分配比例总和不是100%");
            return;
          }
          const departmentIdArr = [];
          let flag = false;
          this.currentAllocationItems.forEach((item) => {
            if (item.departmentId && item.percent) {
              departmentIdArr.push(item.departmentId);
              for (let i = 0; i < departmentIdArr.length - 1; i++) {
                if (departmentIdArr[i] == item.departmentId) {
                  flag = true;
                  break;
                }
              }
            }
          });
          if (flag) {
            this.$message.error("分配比例中有重复字段");
            return;
          }
          this.dialogVisibleOfEdit = false;
          this.currentAllocationItems = this.currentAllocationItems.filter(
            (item) => {
              return item.departmentId && item.percent;
            }
          );
          if (this.currentEditType == "groups") {
            this.happyNewsForm.allocationItems[
              this.currentIndex
            ].allocationItems.groups = JSON.parse(
              JSON.stringify(this.currentAllocationItems)
            );
          } else if (this.currentEditType == "areas") {
            this.happyNewsForm.allocationItems[
              this.currentIndex
            ].allocationItems.areas = JSON.parse(
              JSON.stringify(this.currentAllocationItems)
            );
          } else if (this.currentEditType == "teams") {
            this.happyNewsForm.allocationItems[
              this.currentIndex
            ].allocationItems.teams = JSON.parse(
              JSON.stringify(this.currentAllocationItems)
            );
          } else if (this.currentEditType == "subTeams") {
            this.happyNewsForm.allocationItems[
              this.currentIndex
            ].allocationItems.subTeams = JSON.parse(
              JSON.stringify(this.currentAllocationItems)
            );
          }
        }
      });
    },
    // 获取集团
    getGroups() {
      getGroupsByType().then((res) => {
        if (res.success) {
          this.groupsList = res.result;
        }
      });
    },
    // 获取地区
    getAreas() {
      getAreasByType().then((res) => {
        if (res.success) {
          this.areasList = res.result;
        }
      });
    },
    // 获取团队
    getTeams() {
      getTeamsByType().then((res) => {
        if (res.success) {
          this.teamsList = res.result;
        }
      });
    },
    // 获取子团队
    getSubTeams() {
      getSubTeamsByType().then((res) => {
        if (res.success) {
          this.subTeamsList = res.result;
        }
      });
    },
    //获取行业列表
    loadColumnInList(type) {
      this.ColumnInList = [];
      getCommonColumnList({ type: type }).then((res) => {
        if (res.success) {
          this.ColumnInList = res.result;
        }
      });
    },
    // 获取用户的组织架构
    permissionTree() {
      permissionTree({
        type: "顾问",
        showDisableUser: this.filter.showDisabledUser,
      }).then((res) => {
        if (res.success) {
          this.options2 = res.result;
        }
      });
    },
    // 合并表格
    spanMethod({ row, column }) {
      if (["action"].includes(column.property)) {
        return [row.rowSpan, 1];
      }
    },

    // 表格合并单元格
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex <= 14) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      } else if (columnIndex >= 15 && columnIndex <= 25) {
        // debugger
        const _row = this.spanArr1[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      } else if (columnIndex >= 26 && columnIndex <= 29) {
        // 到账金额 到账时间 到账备注
        const _row = this.spanArr2[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      } else if (columnIndex == 30) {
        // offer业绩修改次数
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    // 导出表格
    exportPerformancePoolList() {
      return new Promise((resolve) => {
        this.exportBtnLoading = true;
        let setData = false;
        let isconfirm = true;
        let source = axios.CancelToken.source();
        var int = setTimeout(function () {
          let that = this;
          setData = true;
          clearInterval(int);
          let div =
            "<p style='font-weight:bold'>后台处理中，稍后请在 " +
            "<a href='/tools/exportingRecords' style='color:#526ecc'>导出记录</a>" +
            " 获取文件</p>";

          if (isconfirm) {
            that.$globalHub
              .$confirm(div, "提示", {
                showCancelButton: false,
                showConfirmButton: false,
                customClass: "altCls",
                dangerouslyUseHTMLString: true,
              })
              .then(() => {})
              .catch(() => {});
            //必须填写终止请求，不然仍会被处理为超时
            source.cancel("终止请求");
          }
        }, 5000);
        exportPerformancePoolList(
          {
            actualAccountEndDate: this.offerT[1] ? this.offerT[1] : "",
            actualAccountStartDate: this.offerT[0] ? this.offerT[0] : "",
            actualMakeInvoiceEndDate: this.CreateT[1] ? this.CreateT[1] : "",
            actualMakeInvoiceStartDate: this.CreateT[0] ? this.CreateT[0] : "",
            candidateName: this.filter.candidateName,
            industryIds: this.industryIds,
            companySource: this.filter.companySource,
            entryEndTime: this.EnterT[1] ? this.EnterT[1] : "",
            entryStartTime: this.EnterT[0] ? this.EnterT[0] : "",
            expectEntryEndTime: this.exEnterT[1] ? this.exEnterT[1] : "",
            expectEntryStartTime: this.exEnterT[0] ? this.exEnterT[0] : "",
            offerStartDate: this.offerEnterT[0] ? this.offerEnterT[0] : "",
            offerEndDate: this.offerEnterT[1] ? this.offerEnterT[1] : "",
            position: this.filter.position,
            status: this.filter.status,
            showDisabledUser: this.filter.showDisabledUser,
            userIds: this.userIds,
            searchKey: this.filter.searchKey,
            accountDateSearchType: this.filter.accountDateSearchType,
            invoiceDateSearchType: this.filter.invoiceDateSearchType,
            checkBox: this.checkedCities,
            page: {
              current: this.pagination.pageNo,
              size: this.pagination.pageSize,
            },
          },
          source.token
        ).then((res) => {
          if (res.success) {
            if (!setData) {
              isconfirm = false;
              location.href = this.baseUrl + res.result;
            }
            this.exportDialog = false;
          }
          resolve();
          this.exportBtnLoading = false;
        });
      });
    },
    //导出实习生\researcher业绩
    exportSxs(type) {
      let that = this;
      return new Promise((resolve) => {
        if (type == "实习生") {
          this.exportLoading = true;
        } else {
          this.exportLoading1 = true;
        }
        let setData = false;
        let isconfirm = true;
        var int = setTimeout(function () {
          let that = this;
          setData = true;
          clearInterval(int);
          let div =
            "<p style='font-weight:bold'>后台处理中，稍后请在 " +
            "<a href='/tools/exportingRecords' style='color:#526ecc'>导出记录</a>" +
            " 获取文件</p>";
          if (isconfirm) {
            that.$globalHub
              .$confirm(div, "提示", {
                showCancelButton: false,
                showConfirmButton: false,
                customClass: "altCls",
                dangerouslyUseHTMLString: true,
              })
              .then(() => {})
              .catch(() => {});
          }
        }, 5000);
        exportOther({
          actualAccountEndDate: this.offerT[1] ? this.offerT[1] : "",
          actualAccountStartDate: this.offerT[0] ? this.offerT[0] : "",
          actualMakeInvoiceEndDate: this.CreateT[1] ? this.CreateT[1] : "",
          actualMakeInvoiceStartDate: this.CreateT[0] ? this.CreateT[0] : "",
          candidateName: this.filter.candidateName,
          industryIds: this.industryIds,
          companySource: this.filter.companySource,
          entryEndTime: this.EnterT[1] ? this.EnterT[1] : "",
          entryStartTime: this.EnterT[0] ? this.EnterT[0] : "",
          expectEntryEndTime: this.exEnterT[1] ? this.exEnterT[1] : "",
          expectEntryStartTime: this.exEnterT[0] ? this.exEnterT[0] : "",
          offerStartDate: this.offerEnterT[0] ? this.offerEnterT[0] : "",
          offerEndDate: this.offerEnterT[1] ? this.offerEnterT[1] : "",
          position: this.filter.position,
          status: this.filter.status,
          showDisabledUser: this.filter.showDisabledUser,
          userIds: this.userIds,
          searchKey: this.filter.searchKey,
          accountDateSearchType: this.filter.accountDateSearchType,
          invoiceDateSearchType: this.filter.invoiceDateSearchType,
          checkBox: that.checkedCities,
          searchKey: that.filter.searchKey,
          performanceOtherTypeEnum: type,
          page: {
            current: this.pagination.pageNo,
            size: this.pagination.pageSize,
          },
        }).then((res) => {
          if (res.success) {
            if (type == "实习生") {
              this.exportLoading = false;
            } else {
              this.exportLoading1 = false;
            }
            if (!setData) {
              isconfirm = false;
              this.fileUrl = res.result;
              location.href = this.baseUrl + res.result;
            }
            resolve();
          }
        });
      });
    },
    // 搜索
    search() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 50;
      this.loadList();
    },
    checkUser(userId, index) {
      if (userId > 0) {
        departAllocations({ userId: userId }).then((res) => {
          if (res.success) {
            this.happyNewsForm.allocationItems[index].allocationItems =
              res.result;
            Object.keys(
              this.happyNewsForm.allocationItems[index].allocationItems
            ).map((i) => {
              if (
                !this.happyNewsForm.allocationItems[index].allocationItems[i]
              ) {
                return (this.happyNewsForm.allocationItems[
                  index
                ].allocationItems[i] = []);
              }
            });
          }
        });
      } else {
        this.happyNewsForm.allocationItems[index].allocationItems = {
          groups: [],
          areas: [],
          teams: [],
          subTeams: [],
        };
      }
    },
    changeShowDisabledUser(e) {
      //展示停用账户
      this.permissionTree();
    },
    changeCreateUserIds(e) {
      this.userIds = e.map((item) => {
        return (item = item[item.length - 1]);
      });
      this.search();
    },
    changeIndustryIds(e) {
      this.industryIds = e.map((item) => {
        return (item = item[item.length - 1]);
      });
      this.search();
    },
    handleVisibleChange(type, e) {
      if (type == 1) {
        if (!e) {
          this.keyOfPartnerIds++;
        }
      } else {
        if (!e) {
          this.keyOfCreateUserIds++;
        }
      }
    },
    filterFun(data, value) {
      // 弹窗 - 拼音过滤
      return data.data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
  },
  mounted() {
    const _this = this;
    _this.noScroll();
    document.onkeydown = function (e) {
      //按下回车提交
      let key = window.event.keyCode;
      //事件中keycode=13为回车事件
      if (key == 13) {
        _this.search();
      }
    };
  },
  destroyed() {
    this.canScroll();
  },
  created() {
    this.isShowChange();
    this.loadList();
    this.loadUserBase();
    // 获取组织框架中的地区
    this.getCompanySourceList();
    this.getGroups(); // 获取集团
    this.getAreas(); // 获取地区
    this.getTeams(); // 获取团队
    this.getSubTeams(); // 获取子团队
    this.loadColumnInList("行业"); //获取行业列表
    this.permissionTree(); //获取用户组织架构
    this.getStore("useInfo");
    this.feedBack();
  },
};
</script>

<style lang="less" scoped>
.editPercent {
  .el-checkbox-group :deep(.el-checkbox) {
    width: 32%;
    margin-right: 0;
    line-height: 24px;
  }
}
.search {
  height: 35px;
  line-height: 35px;
  :deep(.el-input-group__prepend) {
    border-left: 1px solid #e60012;
    border-top: 1px solid #e60012;
    border-bottom: 1px solid #e60012;
    line-height: 33px;
    background-color: white;
  }
  :deep(.el-input__inner) {
    height: 35px !important;
    line-height: 35px !important;
    border-color: #e60012 !important;
    border-right: none;
  }
  :deep(.el-input-group__append) {
    border-color: #e60012 !important;
    background-color: #e60012;
    height: 33px !important;
    line-height: 33px !important;
    color: #fff;
    font-weight: bold;
  }
  :deep(.el-input-group__append:hover) {
    background-color: #cc0000;
  }
}
.warmPrompt {
  font-size: 13px;
  margin: 5px;
}
.box-title {
  border-top: 0;
  padding-top: 0;
  padding-bottom: 15px;
}
.addU {
  width: 560px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border: 1px dashed #ededed;
  color: #999;
  margin: 20px auto;
  display: block;
  cursor: pointer;
  &:hover {
    border-color: #ff3f3f;
    color: #ff3f3f;
  }
}

.resultRearch {
  background: #ff3f3f !important;
  color: white !important;
}
.hoverColorChange {
  font-size: 13px !important;
  color: #606266;
  cursor: pointer;
}
.hoverColorChange:hover {
  color: #ff3f3f;
}
.tabHref1 {
  color: #606266;
  cursor: pointer;
  &:hover {
    text-decoration: underline !important;
  }
}
.listRow {
  background-color: #f5f5f5;
  padding-left: 10px;
  height: 36px;
  line-height: 36px;
  font-weight: bold;
  font-size: 14px;
}
.changeRecordsTit {
  margin-bottom: 5px;
  font-size: 14px;
  color: #606266;
}
.zeroTit {
  font-size: 13px;
  line-height: 23px;
  color: #999;
  margin: 8px 55px;
}
.zfl {
  width: 55px;
  line-height: 25px;
}
</style>
<style lang="less">
.editPercent {
  .el-checkbox__input.is-checked + .el-checkbox__label,
  .el-checkbox__label {
    color: #606266 !important;
  }
}
.savedSearchLabel {
  height: 51px;
  overflow: hidden;
  margin: 10px;
  .el-form-item__label {
    padding: 0;
    height: 51px;
    line-height: 45px;
  }
}
.savedSearchLabel1 {
  height: auto;
  margin: 10px;
  .el-form-item__label {
    padding: 0;
    height: 51px;
    line-height: 45px;
  }
}
.input-with-select.appandSelect {
  vertical-align: middle !important;
  .el-input-group__append {
    background-color: #fff;
    width: 65px;
    border-radius: 0;
  }
  .el-input-group__append:hover {
    border-color: #ededed !important;
  }
}
.input-with-select.appandSelect:hover {
  .el-input-group__append {
    border-color: #ededed !important;
  }
}
.radioGroupBtn {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 0 !important;
  .el-checkbox-button {
    margin-right: 20px;
    box-shadow: none !important;
    .el-checkbox-button__inner {
      border-radius: 0;
      border: 0;
    }
  }
}
.radioGroupBtn.el-radio-group {
  vertical-align: middle;
  .el-radio-button {
    margin-right: 20px;
    box-shadow: none !important;
    .el-radio-button__inner {
      background-color: #f7f7f7;
      border-radius: 0 !important;
      border: 0 !important;
    }
  }
  .el-radio-button.is-active {
    .el-radio-button__inner {
      background-color: #ff3f3f;
      border-radius: 0 !important;
      border: 0 !important;
    }
  }
}
.radioBtn {
  .el-radio__input {
    .el-radio__inner {
      box-shadow: none !important;
    }
  }
}
.el-popover.el-popper.moreCity {
  height: auto;
  width: 240px;
}
.checkboxFontStyle {
  .el-checkbox__label {
    font-size: 12px !important;
  }
}
.searchPanel {
  .el-col.el-col-8,
  .el-col.el-col-12 {
    padding-left: 0 !important;
  }
  .el-input__inner {
    min-width: 180px;
  }
  .el-input-group__append {
    .el-input__inner {
      min-width: 0;
    }
  }
  .appendInput .el-input__inner {
    min-width: 0;
  }
  .el-cascader--mini {
    .el-input__suffix {
      line-height: 34px;
    }
  }
  .el-date-editor .el-range-separator {
    font-size: 13px;
    color: #606266;
  }
}
.offerCss {
  padding: 0;
  .conbox {
    border: 1px solid #ececec;
    margin-bottom: 8px;
    h3 {
      height: 36px;
      line-height: 36px;
      font-size: 14px;
      margin: 0;
      color: #333;
      background-color: #f5f5f5;
      padding: 0 15px;
      .sub {
        font-style: 14px;
        color: #606266;
        i {
          font-style: normal;
          color: #e58a00;
        }
      }
      .fr {
        font-weight: normal;
        .addP {
          font-style: normal;
          font-size: 12px;
          margin-right: 10px;
        }
      }
    }
    .searchList {
      padding: 10px;
      .liStyle {
        height: 44px;
        padding: 0 10px;
        background-color: #f5f5f5;
        .el-input__suffix {
          height: 100% !important;
        }
      }
      .el-icon-delete {
        margin: 0 10px;
        color: #999;
        font-size: 14px;
        &:hover {
          color: #ff3f3f;
        }
      }
      .el-collapse {
        border: none;
      }
      .titleOfPerformance {
        .el-collapse-item__header {
          background-color: #f5f5f5;
          padding-left: 10px;
        }
      }
      .contentOfPerformance {
        background-color: #fff;
        padding: 15px 10px;

        .canEdit {
          padding: 0 10px;
          i {
            display: none;
            color: #898989;
            &:hover {
              color: #e60012;
            }
          }
          &:hover {
            background-color: #f9f9f9;
            i {
              display: inline-block;
            }
          }
          .el-form-item {
            padding: 0;
            margin: 0;
            line-height: 32px;
            .el-form-item__label {
              font-size: 13px;
            }
            .el-form-item__content {
              line-height: 32px;
            }
          }
        }
      }
    }
    .changeRecordList {
      padding: 10px;
      li {
        margin-bottom: 10px;
        .el-collapse-item__header {
          background-color: #f5f5f5;
          padding-left: 10px;
          height: 36px;
          line-height: 1.5;
        }
        .changeRecord {
          border: 1px solid #ececec;
          .el-collapse-item__wrap {
            padding: 10px;
          }
        }
        .changeRecords {
          border: 1px solid #ececec;
          margin-bottom: 10px;
          .el-collapse-item__wrap {
            padding: 10px;
          }
        }
      }
    }
  }
}
.iconOfClose {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #898989;
  &:hover {
    color: #ff3f3f;
  }
}
.formOfPool {
  .el-cascader {
    .el-cascader__search-input {
      min-width: 20px !important;
    }
    .el-cascader__tags {
      .el-tag.el-tag--info.el-tag--mini.el-tag--light {
        max-width: 100px !important;
      }
    }
  }
}
.checkCurson {
  .el-collapse-item__header {
    background-color: #f5f5f5;
    padding-left: 10px;
    height: 32px;
    line-height: 32px;
    border: 0;
    color: #333;
    font-weight: bold;
    font-size: 14px;
  }
  .el-collapse-item__content {
    padding-bottom: 0;
    color: #606266;
  }
  .checkCursonItem {
    margin-bottom: 10px;
    border: 1px solid #ececec;
    .con {
      padding: 10px;
    }
  }
}
.pool {
  .el-table .cell {
    padding-left: 15px !important;
  }
}
.el-form-item {
  margin-bottom: 15px;
  line-height: 28px;
}
</style>
<style lang="scss" scoped>
.el-collapse-item :deep(.el-collapse-item__header) {
  color: #333333;
}
.invoiceNumberInput :deep(input::-webkit-outer-spin-button),
.invoiceNumberInput :deep(input::-webkit-inner-spin-button) {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.pdl20 {
  margin-bottom: 5px;
}
.el-checkbox-group :deep(.el-checkbox) {
  width: 32%;
  margin-right: 0;
}
.el-row :deep(.el-col-12 .el-form-item) {
  margin-bottom: 20px;
}
.pdl20 :deep(.el-col-12 .el-form-item) {
  margin-bottom: 15px;
}
.reChangeList :deep(.el-collapse-item__header) {
  height: 38px;
  line-height: 38px;
}

.el-collapse-item :deep(.el-collapse-item__content) {
  color: #606266;
}
</style>